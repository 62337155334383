import React, { useState } from "react";
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import {
    Typography,
    makeStyles
} from "@material-ui/core";
import { Grid } from "@mui/material"
import emailjs from 'emailjs-com'
import validator from 'validator'
import "./Pages.css"

const useStyles = makeStyles(() => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    mainImage: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '16px'
    },
    homepageMesaages: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '20px'
    }
}));

function ContactUS() {
    const classes = useStyles()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState('')

    // const validateForm = () => {
    //     let sam = false
    //     let errors = {}
    //     let requireFields = ["name", "email", "message"]
    //     let emailFields = ["email"]

    //     requireFields.forEach((field, index) => {
    //       if (document.getElementById(field) !== null) {
    //         let value
    //         if (document.getElementById(field).value !== undefined) {
    //           value = document.getElementById(field).value
    //         } else {
    //           value = document.getElementById(field).innerHTML
    //           if (value == "<span>​</span>") {
    //             value = -1
    //           }
    //         }
    //         if (value.length < 1 || value == -1) {
    //           errors[field] = "This field is required"
    //         } else if (emailFields.indexOf(field) !== -1 && !validator.isEmail(email)) {
    //           errors[field] = "Enter a valid email"
    //         } else if (email != null) {
    //         }
    //         if (sam == false) {
    //         }
    //       }
    //     })
    //     setErrors(errors)
    //     return Object.keys(errors).length > 0 ? true : false
    //   }

    // const validate = () => {
    //     let errors = {}
    //     if(name.length ===0 || email.length ===0 || message.length === 0){
    //         errors['required'] = "Feild is required"
    //     }
    //     else if(!validator.isEmail(email)){
    //         errors['required'] = "Enter a valid email"
    //     }
    //     setErrors(errors)
    //     return Object.keys(errors).length > 0 ? true : false
    // }

    const sendEmail = () => {
        if (name.length > 0 && email.length > 0 && message.length > 0) {
            if (validator.isEmail(email)) {
                const templateParams = {
                    from_name: name,
                    email_id: email,
                    message: message
                }

                emailjs.send('service_wdhy7zh', 'template_58tzkqa', templateParams, 'user_SqSdiYgY8tcYbfIhzhOtD')
                    .then((response) => {
                        alert('Query submitted successfuly.')
                    }, (err) => {
                        alert('There is an error in submitting query')
                        console.log('FAILED...', err)
                    });
            } else {
                setErrorMessage('Please enter valid email address')
            }

        } else {
            setErrorMessage('Please fill required fields.')
        }
    }

    return (
        <div>
            <Paper className="contactus-wrapper">
                <div>
                    <Typography variant="h3" className={classes.mainheading}>
                        Contact Us
                    </Typography>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Paper elevation={3} style={{ padding: '10px' }}>
                            <Typography variant="h5" className={classes.mainheading}>
                                Address
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left' }}>
                                <b>ACTS ACADEMY</b><br />
                                Village - Kalri,<br />
                                Post - Dhourala,<br />
                                Tehsil - Nakur,<br />
                                Dist- saharanpur,<br />
                                PIN - 247340,<br />
                                State - Uttar Pradesh
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left' }}>
                                Phone: +91 9412324080
                            </Typography>
                            <Typography variant="body1" style={{ textAlign: 'left' }}>
                                Email: actsacademysreup@gmail.com
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper elevation={3} style={{ padding: '10px' }}>
                            <Typography variant="h5" className={classes.mainheading}>
                                Submit Any Queries
                            </Typography>
                            <Grid container spacing={2} style={{ padding: '1rem', textAlign: 'center' }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="sName"
                                        label="Name" style={{ width: '100%' }} variant="outlined" value={name} onChange={event => setName(event.target.value)} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        id="Email"
                                        label="Email Adderss"
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        value={email}
                                        onChange={event => setEmail(event.target.value)} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id="outlined-textarea" style={{ width: '100%' }} label="Message" placeholder="Write your message" value={message} onChange={event => setMessage(event.target.value)} multiline />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button size='large' style={{ width: '50%' }} variant="contained" onClick={sendEmail}>Submit</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        style={{ textAlign: "center", color:"red" }}
                                    >
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>


        </div>


    );
}
export default ContactUS;
