import React from "react";
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import {
    Typography,
    makeStyles,
    Grid
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    mainImage: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '16px'
    },
    homepageMesaages: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '20px'
    }
}));

function Admissions() {
    const classes = useStyles()
    return (
        <div>
            <Paper style={{ padding: '1rem' }}>
                <div>
                    <Typography variant="h4" className={classes.subHeading}>
                        Registration & Admission Policy
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1
                            },
                        }}
                    >
                        <Paper elevation={3}>
                            <Typography variant='body1' className={classes.body}>
                                The admission to various classes depends on the availability of seats in each class. Admission is given on the basis of a simple performance test in the month of March. Registration for the test is done in the month of February every year.
                            </Typography>
                        </Paper>
                    </Box>
                </div>
                <div>
                    <Typography variant="h4" className={classes.subHeading}>
                        Admission Process
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1
                            },
                        }}
                    >
                        <Paper elevation={3}>
                            <Typography variant='body1' className={classes.body}>
                                At ACTS Academy we recognize the imerative of imparting an educational experience that is world class in every respect. which prepares children for global citizenship. We believe that a curriculum of excellence with a global dimension is central to the education of children to face the challenges of the 21st century with confidecence and strength of character.
                            </Typography>
                        </Paper>
                    </Box>
                </div>
                <div>
                    <Typography variant="h4" className={classes.subHeading}>
                        Documents Required
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1
                            },
                        }}
                    >
                        <Paper elevation={3} style={{width:'100%', textAlign:'left', padding:'10px'}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">
                                                <p>Admission Form</p>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/* <Typography variant="body1">
                                                Documents Required
                                            </Typography> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                       <p> Birth Certificate</p>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                       <p>Trancfer Certificate of Previous School</p>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                       <p>Report Card of Previous Class</p>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </div>
            </Paper>

        </div>


    );
}
export default Admissions;
