import React from "react"
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import ManagerImage from '../../Images/Principal2.jpeg'
import PrincipalImage from '../../Images/Principal4 - Copy.jpeg'
import {
    Typography,
    makeStyles
} from "@material-ui/core";
import HomePageImages from "../HomePageImages";

const useStyles = makeStyles(() => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    mainImage: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '16px'
    },
    homepageMesaages: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: '2rem',
        marginTop:'3rem',
        flexWrap: 'wrap'
    },
    link: {
        textDecoration: "none",
        color: "#1976d2"
    },
}));

function Home(props) {
    const classes = useStyles()
    return (
        <Paper>
            
            {/* <div className={classes.mainImage}>
                <img src={mainImage} alt="School building" />
            </div> */}
            <HomePageImages />
            <div style={{marginTop:'3rem'}}>
            <Paper elevation={3} >
                <Typography variant="h3" className={classes.subHeading}>
                    ABOUT SCHOOL
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1
                        },
                    }}
                >
                    
                        <Typography variant='body1' className={classes.body}>
                            At ACTS Academy we recognize the imerative of imparting an educational experience that is world class in every respect. which prepares children for global citizenship. We believe that a curriculum of excellence with a global dimension is central to the education of children to face the challenges of the 21st century with confidecence and strength of character.
                        </Typography>
                    
                </Box>
                </Paper>
            </div>
           
            <div style={{marginTop:'3rem'}}>
            <Paper elevation={3} >
                <Typography variant="h3" className={classes.subHeading}>
                    Our Vision
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1
                        },
                    }}
                >
                        <Typography variant='body1' className={classes.body}>
                            <p>Empower the rural and Backward community through Quality English medium Co-Education.</p>
                            <p>ACTS Academy will provide a safe supportive learning environment with opportunities for each student to develop the knowledge, skills and attitude to become a responsible, concerned and committed citizen.</p>
                            <p>We deserves to be card for unconditionally and valuedequally as a unique creation, made in the image of God.</p>
                            <p>In our learnings, works, relationship and the ways inwhich we reorganize the community, we are committed to.</p>
                            <ol>
                                <li>A set of Spiritual and Moral values-honesty, integrity and good judgment.</li>
                                <li>A complete of basic skills-Linguistic, Mathematical, scientific, artistic, physical and social.</li>
                                <li>Strong self-esteem and high personal expectation.</li>
                                <li>Tolerance and respect to others.</li>
                            </ol>
                            <p>We value the partnership which exists between school, students and parents.</p>
                        </Typography>
                </Box>
                </Paper>
            </div>
            <div style={{marginTop:'3rem'}}>
            <Paper elevation={3} >
                <Typography variant="h3" className={classes.subHeading}>
                    OUR MOTTO
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1
                        },
                    }}
                >
                    
                        <Typography variant='body1' className={classes.body}>
                        To Build Storng Foundation For Children's Life with Quality Education and Moral Values Fitted Together in Right way at Right Time.
                        </Typography>
                    
                </Box>
                </Paper>
            </div>
            <div className={classes.homepageMesaages}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={PrincipalImage}
                        alt="principal image"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Principal's Message
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        Education is a gradual process that shows us right path in our life. Education broadens our minds and shows
                             us different paths to success and prosperity.
                        </Typography>
                    </CardContent>
                    <CardActions>
                    <Button onClick={() => {
                            props.handleValue("three")
                        }} size="small" className={classes.link}>Read More</Button>
                    </CardActions>
                </Card>
                <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={ManagerImage}
                        alt="Manager's image"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Manager's Message
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        As per the Moto of the Academy, ‘For God and Country’ our students are prepared
                             to develop deep love and devotion to God and great responsibility towards our 
                             Country.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => {
                            props.handleValue("three")
                        }} size="small" className={classes.link}>Read More</Button>
                    </CardActions>
                </Card>

            </div>

        </Paper>


    );
}
export default Home;
