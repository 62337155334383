import React from "react"
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { styled } from '@mui/material/styles';
// import LocationOnIcon from '@mui/icons-material/LocationOn'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  backgroundColor:'black',
  color:'white'
}));

// let socialMediaData = [
//   { key: 1, icon: "linkedinIcon", link: "https://www.linkedin.com/in/abhishek-parashar-ba6b26a2/" },
//   { key: 2, icon: "facebookIcon", link: "https://www.facebook.com/abhishek.parasar.505/" },
//   { key: 3, icon: "instagramIcon", link: "https://www.instagram.com/parashar427/" },
// ];
// let contactData = [
//   { key: 1, title: "Name", value: "Vardaan Industries"},
//   { key: 2, title: "Address", value: "111/23, Sector- 23, Noida" },
//   { key: 3, title: "Email", value: "vardaan@gmail.com"  },
// ];
function Footer() {
  return (
    <Paper elevation={10} style={{backgroundColor: "black", color:"white"}} className="paper-size" id="contact">
      <Grid container>
        <Grid item xs={6}>
          <Item>
          <Typography style={{textAlign:'center'}} variant="h5" >
              OUR PHILOSOPHY
            </Typography>
            <Typography variant="body1" >
              <i>"Education is not just about going to school and getting a degree. It's about widening your knowledge and absorbing the truth about life."</i>
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
          <Typography style={{textAlign:'center'}} variant="h5" >
          CONTACT US
            </Typography>
          <Grid container spacing={2}>
          <Grid item xs={2}>
            </Grid>
            <Grid item xs={10}>
            <Typography variant="body1" ><b>Address</b>: Village - Kalri, Post - Dhourala, Tehsil - Nakur,<br/> Dist- saharanpur, PIN - 247340, State - Uttar Pradesh</Typography>
            <Typography variant="body1" ><b>Phone</b>: +91 9412324080</Typography>
            <Typography variant="body1" ><b>Email</b>: actsacademysreup@gmail.com</Typography>
            </Grid>
          </Grid>
          </Item>
        </Grid>
        </Grid>
    </Paper>
  );
}

export default Footer;