import React from "react";
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import {
    Typography,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    mainImage: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '16px'
    },
    homepageMesaages:{
        display: 'flex',
    justifyContent: 'space-evenly',
    margin: '20px'
    }
}));

function AboutUs() {
    const classes = useStyles()
    return (
        <div>
            <Paper style ={{padding:'3rem'}}>
            <div>
                <Typography variant="h4" className={classes.subHeading}>
                SCHOOL HISTORY
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1
                        },
                    }}
                >
                    <Paper elevation={3}>
                        <Typography variant='body1' className={classes.body}>
                            <p>Act Academy is the educational unit of Women Hope Foundation based in Dharmapuri district of Tamilnadu. Women Hope Foundation 
                            is a non-profit trust which aims to empower and uplift society irrespective of race, gender, caste, religion, state and language 
                            through various social, economic, and educational welfare measures from 2000 onwards. It essentially aims to help unprivileged people
                             by initiating formal and non-formal education particularly to rural regions, girl child, orphan and women. The 
                             trust is a vocal advocate of giving back to society and country. The trust believes that social development is possible if our country’s rural 
                             children, women are empowered with proper education. The trust, true to its objectives, shows great commitment and dedication to serve people of our 
                             country for more than two decades. The trust never took a back step; rather it withstanding all obstacles including COVID-19 pandemic 
                             and marching forward with the same enthusiasm and zeal to make its vision become reality.</p>
                            <p>In the year 2017 it expanded its social and community service to the state of Uttar Pradesh which has low literacy rate 
                                among many Indian states. The Women Hope Foundation always chooses an area that is most deprived. It aims to make quality English Medium education 
                                accessible to children who are in disadvantage of not living in cities where schools are found in every nook and corner or to the children whose parents cannot 
                                afford the sky-high cost of education.  The trust, after a proper survey, chose village Kalri in Saharanpur to started Acts Academy. The trust has decided to 
                                set up a senior secondary academy and committed to provide necessary funds in phase wise to purchase land and construct buildings in 2018. </p>
                            <p>In the academic year 2019-2020 Acts academy started to admit children after getting Uttar Pradesh state government recognition up to 8th to give quality 
                                English Medium education to the rural children. Acts Academy strives to provide quality English medium education through well qualified and experienced 
                                teaching staff. The academy not just focuses on text-book based education but focuses on all around development of children through quality teachers, curriculum, 
                                extra-curricular activities, physical and moral education. The academy builds a strong foundation at the right age and at the right way and applies tested and 
                                productive methods of teaching based on activities, participation, and self-learning. The academy provides a platform for students to develop their skills, 
                                leadership qualities by arranging function, competition and activities. Teachers are provided in service training to improve teaching and learning skills. 
                                Students and teachers were taken for educational excursions annually.  All girl students, SC/ST students, orphans, semi orphans, academic achievers, 
                                martyrs and handicapped children are given 25% to 100% concession in tuition fees. The school has an environmentally friendly campus with well-furnished 
                                class rooms, library, playground and labs. The campus is equipped with 24x7 solar power and internet facilities. The academy is well protected with 
                                a compound wall, CCTV and ensures safe and secure transportation.</p>
                        </Typography>
                    </Paper>
                </Box>
            </div>
            <div>
                <Typography variant="h4" className={classes.subHeading}>
                    RULES AND REGULATION
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1
                        },
                    }}
                >
                    <Paper elevation={3}>
                        <Typography variant='body1' className={classes.body}>
                            At ACTS Academy we recognize the imerative of imparting an educational experience that is world class in every respect. which prepares children for global citizenship. We believe that a curriculum of excellence with a global dimension is central to the education of children to face the challenges of the 21st century with confidecence and strength of character.
                        </Typography>
                    </Paper>
                </Box>
            </div>
            </Paper>

        </div>


    );
}
export default AboutUs;
