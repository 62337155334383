import React, { Fragment } from "react";
import Logo from "../Images/Logo.jpeg";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  Avatar,
} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { DownArrowCircle } from "../Images/Icons";
import "../Styles/Header.css";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
    alignItems: "center",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  name: {
    flexGrow: "1",
  },
}));

function Header() {
  const classes = useStyles();

  return (
    <Fragment>
      <AppBar position="static">
        <CssBaseline />
        <Toolbar>
          <div className={classes.logo}>
            <img className="header-logo" src={Logo} alt="School logo" />
          </div>
          <div className={classes.name}>
            <Typography variant="h3" className="header-school-name">
              ACTS ACADEMY
            </Typography>
          </div>
          {window.location.pathname.split("/")[1].includes('dashboard') && <div className="Profile-wrapper">
            <div className="Profile-Avatar">
              <Avatar sx={{ bgcolor: "purple" }}>VP</Avatar>
            </div>
            <div className="Profile-Info">
              <div className="Profile-Info-name">Vinay Panwar</div>
              <div className="Profile-Info-role">Super Admin</div>
            </div>
            {/* <DownArrowCircle /> */}
          </div>}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}
export default Header;
