export const validationObject = {
  type: "",
  message: "",
};

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const validateFields = (fields, values) => {
  let isError = false;
  let errors = {
    email: { ...validationObject },
    password: { ...validationObject },
    otp: { ...validationObject },
    confirmPassword: { ...validationObject },
  };
  if (fields.includes("email")) {
    if (!values.email) {
      errors.email.type = "required";
      errors.email.message = "Please enter email";
      isError = true;
    } else if (!emailRegex.test(values.email)) {
      errors.email.type = "type";
      errors.email.message = "Please enter valid email";
      isError = true;
    }
  }
  if (fields.includes("password")) {
    if (!values.password) {
      errors.password.type = "required";
      errors.password.message = "Please enter password";
      isError = true;
    } else if (values.password.length < 8) {
      errors.password.type = "length";
      errors.password.message = "Please enter valid eight digit password";
      isError = true;
    }
  }
  if (fields.includes("otp")) {
    if (values.otp && values.otp.length === 0) {
      errors.otp.type = "required";
      errors.otp.message = "Please enter otp";
      isError = true;
    } else if (values.otp && values.otp.length < 6) {
      errors.otp.type = "length";
      errors.otp.message = "Please enter valid six digit otp";
      isError = true;
    }
  }
  if (fields.includes("confirmPassword")) {
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword.type = "type";
      errors.confirmPassword.message = "Passwords does not match";
      isError = true;
    }
  }
  return isError ? errors : {};
};
