import React from "react";
import "../../Styles/login.css";
import CustumTextField from "../Inputs/TextField";
import { Avatar, Box, Container, CssBaseline, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useForm } from "react-hook-form";
import CustumButton from "../Buttons/CustomButton";
import { validateFields } from "../../HelperFunctions/Validations";
import { Link, useNavigate } from "react-router-dom";

const resolver = async (values) => {
  const fields = ["email", "password"];
  const responseError = validateFields(fields, values);
  return {
    values: values,
    errors: responseError,
  };
};

export default function Login() {
  const navigate = useNavigate();

  const methods = useForm({
    resolver,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = (data) => {
    console.log({ data: data });
    navigate("/dashboard");
  };

  return (
    <div className="login-wrapper">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <CustumTextField
              label="Email"
              required={true}
              name="email"
              fullWidth={true}
              control={control}
              error={!!errors?.email?.message}
              errorMessage={errors?.email?.message}
            />
            <CustumTextField
              label="Password"
              required={true}
              name="password"
              type="password"
              fullWidth={true}
              control={control}
              error={!!errors?.password?.message}
              errorMessage={errors?.password?.message}
            />
            <CustumButton value="sign in" variant="contained" type="submit" />
            <Link className="forgit-link" to={"/forgot"} variant="body2">
              Forgot password?
            </Link>
          </form>
        </Box>
      </Container>
    </div>
  );
}
