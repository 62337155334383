import { Button } from "@mui/material"

export default function CustumButton(props) {
    const {value, variant, onclick, type} = props
    return (
        <Button
            variant={variant}
            onclick={onclick}
            type={type}
        >{value}
        </Button>
    )
}