import React, { Fragment } from "react"
import {
    makeStyles,
    useTheme,
    Paper,
    Grid
} from "@material-ui/core"
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Building1 from '../../Images/TcImages/Building1.jpeg'
import Building2 from '../../Images/TcImages/Building2.jpeg'
import building3 from '../../Images/TcImages/building3.jpeg'
import building4 from '../../Images/TcImages/building4.jpeg'
import building5 from '../../Images/TcImages/building5.jpeg'
import Activity1 from '../../Images/Activity/Activity1.jpeg'
import Activity2 from '../../Images/Activity/Activity2.jpeg'
import Activity3 from '../../Images/Activity/Activity6.jpeg'
import Activity4 from '../../Images/Activity/Activity4.jpeg'
import Activity7 from '../../Images/Activity/Activity7.jpeg'
import Activity8 from '../../Images/Activity/Activity8.jpeg'
import Activity9 from '../../Images/Activity/Activity9.jpeg'
import Activity10 from '../../Images/Activity/Activity10.jpeg'
import Activity11 from '../../Images/Activity/Activity11.jpeg'
import Activity12 from '../../Images/Activity/Activity12.jpeg'
import Activity13 from '../../Images/Activity/Activity13.jpeg'
import Activity14 from '../../Images/Activity/Activity14.jpeg'
import Activity15 from '../../Images/Activity/Activity15.jpeg'
import Activity16 from '../../Images/Activity/Activity16.jpeg'
import Activity17 from '../../Images/Activity/Activity17.jpeg'
import Activity18 from '../../Images/Activity/Activity18.jpeg'
import Ground1 from '../../Images/TcImages/building6.jpeg'
import Ground2 from '../../Images/TcImages/building7.jpeg'
import Ground3 from '../../Images/TcImages/building8.jpeg'
import Lab1 from '../../Images/lab1.jpeg'
import Lab2 from '../../Images/lab2.jpeg'
import Lab3 from '../../Images/lab3.jpeg'
import Lab4 from '../../Images/lab4.jpeg'
import Lab5 from '../../Images/lab5.jpeg'
import Tolet1 from '../../Images/tolet1.jpeg'
import Tolet2 from '../../Images/tolet2.jpeg'

const useStyles = makeStyles((theme) => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    docImageDiv: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '0px'
    },
    imageWidth:{
        width: '80%'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Gallery() {
    const classes = useStyles()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Fragment>
            <div className={classes.body}>
                <Paper elevation={1}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h3" className={classes.mainheading}>
                            Gallery
                        </Typography>
                    </div>
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="School Building" {...a11yProps(0)} />
                            <Tab label="Activities" {...a11yProps(1)} />
                            <Tab label="Ground" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0} style={{width:'100%'}}>
                            <div className={classes.docImageDiv}>
                                <Grid container spacing={2}>
                                    
                                <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={building5} alt='school building from front' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Building2} alt='school building from front' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={building3} alt='school building from front' />
                                    </Grid>
                                    

                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={building4} alt='school building from front' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Building1} alt='school building' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Lab1} alt='school building' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Lab2} alt='school building' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Lab3} alt='school building' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Lab4} alt='school building' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Lab5} alt='school building' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Tolet1} alt='school building' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Tolet2} alt='school building' />
                                    </Grid>
                                </Grid>
                                
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} style={{width:'100%'}}>
                            <div className={classes.docImageDiv}>
                            <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity1} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity2} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity3} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity4} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity7} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity8} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity9} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity10} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity11} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity12} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity13} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity14} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity15} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity16} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity17} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Activity18} alt='Activities in school' />
                                    </Grid>
                                </Grid>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2} style={{width:'100%'}}>
                            <div className={classes.docImageDiv}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Ground1} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Ground2} alt='Activities in school' />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <img className={classes.imageWidth} src={Ground3} alt='Activities in school' />
                                    </Grid>
                                </Grid>
                            </div>
                        </TabPanel>                  
                    </Box>
                </Paper>
            </div>
        </Fragment>
    );
}
export default Gallery;