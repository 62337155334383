import React, { Fragment } from "react"
import {
    makeStyles,
    useTheme,
    Paper
} from "@material-ui/core"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import fireCertificate from '../../Images/Certificates/fireCertificate.jpeg'
import trustDeed1 from '../../Images/Certificates/TrustDeed1.jpeg'
import trustDeed2 from '../../Images/Certificates/TrustDeed2.jpeg'
import NOC from '../../Documents/NOC Certificate 3.pdf'
import AcademicCalender from '../../Documents/Academic Calender 10.pdf'
import Fees from '../../Documents/FEES STRUCTURE 9.pdf'
import Fire from '../../Documents/Fire6.pdf'
import Recog from '../../Documents/Recog 4.pdf'
import Self from '../../Documents/Self Certification 7.pdf'
import SMC from '../../Documents/SMC 11.pdf'
import Trust from '../../Documents/Trust 2.pdf'
import NBC from '../../Documents/NBC 5.pdf'
import Water from '../../Documents/Water 8.pdf'
import PTA from '../../Documents/PTA 12.pdf'
import PublicActs from '../../Documents/Public Acts.pdf'
import GrantLetter from '../../Documents/Latest Grant Letter 1.pdf'
import ThreeYearResult from '../../Documents/Result 13.pdf'
import Recog10 from '../../Documents/Recog 10.pdf'

const useStyles = makeStyles((theme) => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    docImageDiv: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '20px'
    },
    imageWidth:{
        width: '80%'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Documents() {
    const classes = useStyles()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Fragment>
            <div>
                <Paper elevation={1}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h3" className={classes.mainheading}>
                            Mandatory Public Disclosure
                        </Typography>
                    </div>
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Documents" {...a11yProps(0)} />
                            <Tab label="Fire Certificate" {...a11yProps(1)} />
                            <Tab label="Trust Deed certificate" {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={value} index={0} style={{width:'100%'}}>
                            <div className={classes.docImageDiv}>
                                <ul style={{listStyle: 'none'}}>
                                    <li><a href={PublicActs} target='_blank' rel='noopener noreferrer'>Mandatory Public Disclosure Certificate</a></li>
                                    <li><a href={NOC} target='_blank' rel='noopener noreferrer'>NOC Certificate</a></li>
                                    <li><a href={AcademicCalender} target='_blank' rel='noopener noreferrer'>Academic Calender</a></li>
                                    <li><a href={Fees} target='_blank' rel='noopener noreferrer'>Fees Structure</a></li>
                                    <li><a href={Fire} target='_blank' rel='noopener noreferrer'>Fire Certificate</a></li>
                                    <li><a href={Recog10} target='_blank' rel='noopener noreferrer'>10th Recognization Certificate</a></li>
                                    <li><a href={Recog} target='_blank' rel='noopener noreferrer'>Recognization Certrificate</a></li>
                                    <li><a href={SMC} target='_blank' rel='noopener noreferrer'>SMC</a></li>
                                    <li><a href={Self} target='_blank' rel='noopener noreferrer'>Self Certificate</a></li>
                                    <li><a href={Trust} target='_blank' rel='noopener noreferrer'>Trust Deed</a></li>
                                    <li><a href={NBC} target='_blank' rel='noopener noreferrer'>Building Safety Certificate</a></li>
                                    <li><a href={Water} target='_blank' rel='noopener noreferrer'>Water Sanitation Certificate</a></li>
                                    <li><a href={PTA} target='_blank' rel='noopener noreferrer'>PTA</a></li>
                                    <li><a href={GrantLetter} target='_blank' rel='noopener noreferrer'>Grant Letter</a></li>
                                    <li><a href={ThreeYearResult} target='_blank' rel='noopener noreferrer'>Last Three Years Academic Result</a></li>
                                </ul>
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1} style={{width:'100%'}}>
                            <div className={classes.docImageDiv}>
                                <img className={classes.imageWidth} src={fireCertificate} alt='fire certificate' />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2} style={{width:'100%'}}>
                            <div className={classes.docImageDiv}>
                                <img className={classes.imageWidth} src={trustDeed1} alt='Trust deed certificate' />
                                <img className={classes.imageWidth} src={trustDeed2} alt='Trust deed certificate' />
                            </div>
                        </TabPanel>
                        
                    </Box>
                </Paper>
            </div>
        </Fragment>
    );
}
export default Documents;