import React from "react"
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import ManagerImage from '../../Images/Principal3.jpeg'
import PrincipalImage from '../../Images/Principal4.jpeg'
import {
    Typography,
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    mainImage: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '16px'
    },
    homepageMesaages: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '20px'
    }
}));

function Messages() {
    const classes = useStyles()
    return (
        <div>
            <div>
                <Card>
                    <CardMedia
                        component="img"
                        height="600"
                        image={PrincipalImage}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h4" component="div">
                            Principal's Message
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <p>
                            Education is a gradual process that shows us right path in our life. Education broadens our minds and shows
                             us different paths to success and prosperity. It helps us to tackle many fundamental probleme like poverty, 
                             unemployment, casteism, racism etc. An educated person work as an ambassador for the community
                             and for the country to inculcate moral values and prevent the erosion of social values.
                             </p>
                             <p>
                             In India the population of women is nearly equal when compared to men's since ancient times woman has been discriminated 
                             on the ground of gender. Nowadays, the status of women in India has greatly improved. Today, they are occupying grand 
                             esteemed designations in different departments of government and private sectors. But, this is not enough. They are to be 
                             supported economically, socially and they should be imparted higher education nearly free at cost. We should move in the 
                             direction of going all the essential and propelled education to the little girl in our family. This is because a girl today,
                              a mother tomorrow will educate her family and play a significant role in building a strong nation which is demand of today. 
                              In this regard W. H. F is truly playing an appreciable role in providing education to girl child. W.H.F specially emphasizing 
                              the girl child education in  rural areas where parents can not afford the expenses for their ambitious girls. All this really
                               praise worthy should be encouraged and supported.
                             </p>
                             
                        </Typography>
                    </CardContent>
                </Card>

                <div style={{marginTop:'20px'}}>
                <Card>
                    <CardMedia
                        component="img"
                        height="400"
                        image={ManagerImage}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h4" component="div">
                           Manager’s Message
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            <p>
                            As per the Moto of the Academy, ‘For God and Country’ our students are prepared
                             to develop deep love and devotion to God and great responsibility towards our 
                             Country. 
                             The team of efficient and effective teachers form not only the head but also the heart and hands of every 
                             student at the Academy.
                             </p>
                             <p>
                             In today’s fast changing world ACTS Academy prepares the students to face the world with confidence and the competitions with competence. Various curricular and co-curricular
                              activities provided at school develop the hidden talents and skills of students. As technology has taken its place in all walks of life, students are educated to handle technology with responsibility through computer education and online program.
                              At the same time parents are given information online to monitor the progress of their wards.
                             </p>
                             <p>
                                 “Education is not the filling of the pail, but the lighting of the lamp.”- W.B. Yeats. The young minds
                                  at the Academy are kindled with the fire of knowledge that can illumine the whole world.
                                   Jesus said, ’Let little children come unto me”. This same sentiment is being practiced by all
                                    teachers here as they care so lovingly and participate in the growth and development of every student. ACTS Academy stands as a sign of quality and excellence in all its educational activities and integral formation.

                             </p>

                        </Typography>
                    </CardContent>
                </Card>
                </div>
            </div>

        </div>


    );
}
export default Messages;
