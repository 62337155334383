import { Box, Container, CssBaseline, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import CustumTextField from "../Inputs/TextField";
import { validateFields } from "../../HelperFunctions/Validations";
import CustumButton from "../Buttons/CustomButton";
import { useState } from "react";
import { useNavigate } from "react-router";

const resolver = async (values) => {
  const fields = ["email", "otp"];
  const responseError = validateFields(fields, values);
  return {
    values: values,
    errors: responseError,
  };
};

export default function ForgotPassword() {
  const [showOtp, setShowOtp] = useState(false);
  const navigate = useNavigate()
  const methods = useForm({
    resolver,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = (data) => {
    if(showOtp) navigate("/reset")
    setShowOtp(true);
    console.log({ data: data });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <CustumTextField
            label="Email"
            required={true}
            name="email"
            fullWidth={true}
            control={control}
            error={!!errors?.email?.message}
            errorMessage={errors?.email?.message}
          />
          {showOtp && (
            <CustumTextField
              label="OTP"
              required={true}
              name="otp"
              fullWidth={true}
              control={control}
              error={!!errors?.otp?.message}
              errorMessage={errors?.otp?.message}
            />
          )}
          <CustumButton
            value={showOtp ? "Submit" : "send OTP"}
            variant="contained"
            type="submit"
          />
        </form>
      </Box>
    </Container>
  );
}
