import { TextField, InputLabel } from "@mui/material";
import { Controller } from "react-hook-form";
import "../../Styles/TextField.css";
import { useState } from "react";

export default function CustumTextField(props) {
  const {
    name,
    value,
    error,
    label,
    type,
    required,
    errorMessage,
    fullWidth,
    control,
  } = props;
  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        //fieldState: { error },
        formState,
      }) => (
        <div>
          {label && (
            <InputLabel
              required={required}
              className={`input-label ${
                focused ? "focused" : error ? "label-error" : ""
              }`}
            >
              {label}
            </InputLabel>
          )}
          <TextField
            //helperText={error ? error.message : null}
            helperText={errorMessage}
            size="small"
            error={!!error}
            onChange={onChange}
            value={value}
            fullWidth={fullWidth}
            type={type}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      )}
    />
  );
}
