import React, { Fragment } from "react"
import {
    makeStyles,
    Paper
} from "@material-ui/core"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import trustDeed1 from '../../Images/Certificates/TrustDeed1.jpeg'
import trustDeed2 from '../../Images/Certificates/TrustDeed2.jpeg'
import Admissions from "./AcdemicsDetails/Admission";


const useStyles = makeStyles((theme) => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    docImageDiv: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
    },
    imageWidth:{
        width: '80%'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Academics() {
    const classes = useStyles()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Fragment>
            <div className={classes.body}>
                <Paper elevation={1}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h3" className={classes.mainheading}>
                            Academics
                        </Typography>
                    </div>
                    <Box
                        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab label="Addmission" {...a11yProps(0)} />
                            <Tab label="Uniform" {...a11yProps(1)} />
                            <Tab label="Academic Calender" {...a11yProps(1)} />
                            <Tab label="Fee Structure" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={value} index={0} style={{width:'100%'}}>
                            <Admissions />
                        </TabPanel>
                        <TabPanel value={value} index={1} style={{width:'100%'}}>
                            <div className={classes.docImageDiv}>
                                <img className={classes.imageWidth} src={trustDeed1} alt='Trust deed certificate 1' />
                                <img className={classes.imageWidth} src={trustDeed2} alt='Trust deed certificate 2' />
                            </div>
                        </TabPanel>
                        
                    </Box>
                </Paper>
            </div>
        </Fragment>
    );
}
export default Academics;