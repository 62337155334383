import { Box, Container, CssBaseline, Typography } from "@mui/material";
import CustumTextField from "../Inputs/TextField";
import CustumButton from "../Buttons/CustomButton";
import { useForm } from "react-hook-form";
import { validateFields } from "../../HelperFunctions/Validations";
import { useNavigate } from "react-router";

const resolver = async (values) => {
  const fields = ["password", "confirmPassword"];
  const responseError = validateFields(fields, values);
  return {
    values: values,
    errors: responseError,
  };
};

export default function ResetPassword() {
  const methods = useForm({
    resolver,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const navigate = useNavigate()
  const onSubmit = (data) => {
    console.log({ data: data });
    navigate("/login")
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <CustumTextField
            label="Password"
            required={true}
            name="password"
            fullWidth={true}
            control={control}
            error={!!errors?.password?.message}
            errorMessage={errors?.password?.message}
            type="password"
          />

          <CustumTextField
            label="Confirm Password"
            required={true}
            name="confirmPassword"
            fullWidth={true}
            control={control}
            error={!!errors?.confirmPassword?.message}
            errorMessage={errors?.confirmPassword?.message}
            type="password"
          />
          <CustumButton value="Submit" variant="contained" type="submit" />
        </form>
      </Box>
    </Container>
  );
}
