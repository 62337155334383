import { Routes, Route } from "react-router-dom";
import MainNavBar from "./Components/MainNavBar";
import Login from "./Components/LoginFlow/login";
import ForgotPassword from "./Components/LoginFlow/ForgotPassword";
import ResetPassword from "./Components/LoginFlow/ResetPassword";
import Dashboard from "./Components/Dashboard/Dashboard";

export default function RenderRoutes() {
  return (
    <Routes>
      <Route exact path="/" element={<MainNavBar />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/forgot" element={<ForgotPassword />} />
      <Route exact path="/reset" element={<ResetPassword />} />
      <Route exact path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
}
