import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types'
import Home from "./pages/home";
import AboutUs from "./pages/aboutUs";
import Messages from "./pages/Messages";
import Gallery from "./pages/Gallery";
import Documents from "./pages/documents";
import TransferCertificate from "./pages/Tc";
import Academics from "./pages/Academics";
import ContactUS from "./pages/ContactUs";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  MainGrid: {

    backgroundColor: '#e3e3e3',
    padding: '2%'
  },
  LoginPaper: {
    borderRadius: '12px',
    padding: '9px'
  },
  Heading: {
    fontSize: '25px',
    fontWeight: '600'
  },
  SubHeading: {
    fontSize: '17px',
  }
  // root: {
  //   ['@media (max-width:750px)']: { // eslint-disable-line no-useless-computed-key
  //     padding: '0px',
  //   }
  // }
}))
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const MainNavBar = props => {
  const classes = useStyles()
  const [value, setValue] = React.useState('one');

  useEffect(() => {
    const tab = localStorage.getItem('selectedTab')
    if (tab)
    {
      setValue(tab);
      }
   
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("selectedTab",newValue)
  };
  const handleValue = updatedValue => {
    setValue(updatedValue)
  }
  return (
    <Grid container style={{ minHeight: "775px" }} className={classes.MainGrid}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Grid container className={classes.Grids} spacing={3}>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <AppBar position="static">
              <Tabs variant="scrollable" value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                <Tab
                  value="one"
                  label="Home"

                  {...a11yProps('one')}
                />
                <Tab value="two" label="About Us" {...a11yProps('two')} />
                <Tab value="three" label="Messages" {...a11yProps('three')} />
                <Tab value="four" label="Gallery" {...a11yProps('four')} />
                <Tab value="six" label="Academics" {...a11yProps('six')} />
                <Tab value="five" label="Mandatory Public Disclosures" {...a11yProps('five')} />
                <Tab value="seven" label="Contact Us" {...a11yProps('seven')} />
                <Tab value="twelve" label="TC" {...a11yProps('twelve')} />
                

              </Tabs>
            </AppBar>
            <TabPanel value={value} index="one">
              <Home handleValue={handleValue}/>
            </TabPanel>
            <TabPanel value={value} index="two">
              <AboutUs />
            </TabPanel>
            <TabPanel value={value} index="three">
              <Messages />
            </TabPanel>
            <TabPanel value={value} index="four">
              <Gallery />
            </TabPanel>
            <TabPanel value={value} index="five">
              <Documents />
            </TabPanel>
            <TabPanel value={value} index="twelve">
              <TransferCertificate />
            </TabPanel>
            <TabPanel value={value} index="six">
              <Academics />
            </TabPanel>
            <TabPanel value={value} index="seven">
              <ContactUS />
            </TabPanel>
             {/* <TabPanel value={value} index="onBoard">
              <OnBoard />
            </TabPanel> */}
            {/* <TabPanel value={value} index="nine">
              <EnrollStudents />
            </TabPanel>
            <TabPanel value={value} index="ten">
              <PromoteStudents />
            </TabPanel> */}
            {/* <TabPanel value={value} index="eleven">
              <StudentInfo />
            </TabPanel> */}
            {/* <TabPanel value={value} index="twelve">
              <EnrollHome />
            </TabPanel> */}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>

          </Grid>

        </Grid>

      </Grid>

    </Grid>
  )
}

export default MainNavBar;

