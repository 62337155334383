import React, { Fragment, useState } from "react"
import {
    Typography,
    makeStyles,
    useTheme,
    Paper,
} from "@material-ui/core"
import TextField from '@mui/material/TextField';
import Grid from "@material-ui/core/Grid";
import Button from '@mui/material/Button'
import TcImage1 from '../../Images/TcImages/Building1.jpeg'
import "./Pages.css"

const useStyles = makeStyles((theme) => ({
    mainheading: {
        textDecoration: "none",
        color: "#1976d2"
    },
    mainImage: {
        textAlign: 'center'
    },
    subHeading: {
        marginTop: '16px',
        color: "#1976d2"
    },
    body: {
        margin: '20px'
    }
}));

const tcImages = [
    { key: 1, name: 'Vinay Panwar', addmissionNO: "12345", link: TcImage1 },
    { key: 2, name: 'Aksay Panwar', addmissionNO: "278439", link: "https://www.linkedin.com/in/abhishek-parashar-ba6b26a2/" },
    { key: 3, name: 'Anay Panwar', addmissionNO: "398748", link: "https://www.linkedin.com/in/abhishek-parashar-ba6b26a2/" },
    { key: 4, name: 'Aryan Panwar', addmissionNO: "38498", link: "https://www.linkedin.com/in/abhishek-parashar-ba6b26a2/" }
]

function TransferCertificate() {
    const classes = useStyles()

    const [name, setName] = useState("")
    const [admissionNo, setAdmissionNo] = useState("")
    const [showImage, setShowImage] = useState(false)
    const [selectedImage, setSelectedImage] = useState("")


    function handleNameChange(event) {
        setName(event.target.value)
    }

    function handleAdmissionNo(event) {
        setAdmissionNo(event.target.value)
    }

    function searchImage() {
        if (name.length === 0 || admissionNo.length === 0) {
            alert('Please enter student Name and Admission Number')
        }
        else {
            var newArray = tcImages.filter(function (el) {
                return el.name == name &&
                    el.addmissionNO == admissionNo
            })
            if (newArray.length) {
                setShowImage(true)
                setSelectedImage(newArray[0].link)
            }
            else {
                alert('No student found')
            }
        }
    }

    return (
        <Fragment>
            <div>
                <Paper elevation={1}>
                    <div style={{ padding: '20px' }}>
                        <Typography variant="h3" className={classes.mainheading}>
                            Transfer Certificate
                        </Typography>

                        <Grid container spacing={4} className="tc-grid-container">
                            <Grid item xs={12} sm={6}>
                                <TextField id="studentName" label="Student Name" style={{width:'100%'}} variant="outlined" onChange={handleNameChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField id="admissiinNo" label="Student Addmision No" style={{width:'100%'}} variant="outlined" onChange={handleAdmissionNo} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button size='large' style={{width:'50%'}} variant="contained" onClick={searchImage}>Submit</Button>
                            </Grid>
                        </Grid>
                    </div>

                    {showImage ?
                        <div>
                            <img style={{width:'100%'}} src={selectedImage} alt='student TC'/>
                        </div> :
                        null}

                </Paper>
            </div>

        </Fragment>
    );
}
export default TransferCertificate;