import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

import Footer from "./Components/Footer";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import Header from "./Components/header";
import { excludeFooter, excludeHeader } from "./Constants/HeaderConstants";
import RenderRoutes from "./Routes";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "rgb(220, 0, 78)",
    },
  },
});

function App() {
  const currentPath = window.location.pathname.split("/")[1];

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        {!excludeHeader.includes(currentPath) && <Header />}
        <RenderRoutes />
        {!excludeFooter.includes(currentPath) && <Footer />}
      </MuiThemeProvider>
    </Router>
  );
}

export default App;
