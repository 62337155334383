import React from "react"
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import mainImage from '../Images/MainImage.jpeg'
import Building1 from '../Images/TcImages/Building1.jpeg'
import Activity1 from '../Images/Activity2.jpeg'

export default function HomePageImages() {
    return (
        <div className="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showStatus={false} showThumbs={false}>
                <div>
                    <img src={mainImage} alt="School building" />  
                </div>
                <div>
                    <img src={Building1} alt='school building from front' />
                </div>
                <div>
                    <img src={Activity1} alt='school building from front' />
                </div>
            </Carousel>
        </div>
    );
}